import { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
  CssBaseline,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import alunoService from "../alunoService";
import { format } from "date-fns";

const defaultTheme = createTheme();

export default function FormEditUser() {
  const navigate = useNavigate();
  const { showAluno, editUser, editAluno } = alunoService();
  const { uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    nome: "",
    email: "",
    genero: "",
    cpf: "",
    data_nascimento: "",
    telefone: "",
    ativo: true,
  });
  const [aluno, setAluno] = useState({
    endereco: "",
    altura: "",
    profissao: "",
    local_trabalho: "",
    plano_saude: "",
    contato_emergencia: "",
  });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubmitPutUser = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const formatData = async () => {
      const partes = data.get("data_nascimento").split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      nome: data.get("nome"),
      email: data.get("email"),
      sexo: data.get("genero"),
      cpf: data.get("cpf").replace(/[^0-9]/g, ""),
      data_nascimento: await formatData(),
      telefone: data.get("telefone").replace(/\s/g, ""),
      ativo: data.get("ativo") ? 1 : 0,
    };

    try {
      await editUser(form, uuid);
      navigate("/alunos");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
        alert("Necessário preencher todos os campos");
      } else {
        console.error(error.message);
      }
    }
  };

  const handleSubmitPutAluno = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const form = {
      uuid: uuid,
      endereco: data.get("endereco"),
      altura: data.get("altura"),
      profissao: data.get("profissao"),
      local_trabalho: data.get("local_trabalho"),
      plano_saude: data.get("plano_saude"),
      contato_emergencia: data.get("contato_emergencia"),
      user_uuid: uuid,
    };

    try {
      await editAluno(form, uuid);
      navigate("/alunos");
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
        alert("Necessário preencher todos os campos");
      } else {
        console.error(error.message);
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleInputChangeAluno = (e) => {
    const { name, value } = e.target;
    setAluno((prevUser) => ({ ...prevUser, [name]: value }));
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await showAluno(uuid);
        const userData = response.data;
        const formattedData = async (data) => {
          return format(new Date(data), "dd/MM/yyyy");
        };
        setUser({
          nome: userData.nome || "",
          email: userData.email || "",
          genero: userData.sexo || "F",
          cpf: userData.cpf || "",
          data_nascimento: await formattedData(userData.data_nascimento),
          telefone: userData.telefone || "",
          ativo: userData.ativo || true,
        });
        setAluno({
          endereco: userData.endereco || "",
          altura: userData.altura || "",
          profissao: userData.profissao || "",
          local_trabalho: userData.local_trabalho || "",
          plano_saude: userData.plano_saude || "",
          contato_emergencia: userData.contato_emergencia || "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    }
    getData();
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toolbar />
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Usuário" />
              <Tab label="Aluno" />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmitPutUser}
              sx={{ mt: 3 }}
            >
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="nome"
                    label="Nome"
                    name="nome"
                    value={user.nome}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={user.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12}>
                    <FormLabel id="genero">Genero</FormLabel>
                  </Grid>
                  <FormControlLabel
                    value="F"
                    control={<Radio checked={user.genero === "F"} />}
                    label="Feminino"
                    name="genero"
                    onChange={handleInputChange}
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio checked={user.genero === "M"} />}
                    label="Masculino"
                    name="genero"
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="999.999.999-99"
                    value={user.cpf}
                    onChange={handleInputChange}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="99/99/9999"
                    value={user.data_nascimento}
                    onChange={handleInputChange}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="data_nascimento"
                        label="Nascimento"
                        name="data_nascimento"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="99 999999999"
                    value={user.telefone}
                    onChange={handleInputChange}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="telefone"
                        label="Contato"
                        name="telefone"
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    id="ativo"
                    required
                    control={<Switch checked={user.ativo} />}
                    label="Ativo"
                    name="ativo"
                    onChange={() =>
                      setUser((prevUser) => ({
                        ...prevUser,
                        ativo: !prevUser.ativo,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} mt={8}>
                <Button
                  sx={{ marginRight: "8px" }}
                  onClick={() => {
                    navigate("/alunos");
                  }}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Gravar
                </Button>
              </Grid>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmitPutAluno}
              sx={{ mt: 3 }}
            >
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="endereco"
                    label="Endereço"
                    name="endereco"
                    value={aluno.endereco}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="altura"
                    label="Altura"
                    name="altura"
                    placeholder="1.82"
                    value={aluno.altura}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="profissao"
                    label="Profissão"
                    name="profissao"
                    value={aluno.profissao}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="local_trabalho"
                    label="Local de trabalho"
                    name="local_trabalho"
                    value={aluno.local_trabalho}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="plano_saude"
                    label="Plano de Saude"
                    name="plano_saude"
                    value={aluno.plano_saude}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="contato_emergencia"
                    label="Contato de emergência"
                    name="contato_emergencia"
                    value={aluno.contato_emergencia}
                    onChange={handleInputChangeAluno}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} mt={8}>
                <Button
                  sx={{ marginRight: "8px" }}
                  onClick={() => {
                    navigate("/alunos");
                  }}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Gravar
                </Button>
              </Grid>
            </Box>
          </CustomTabPanel>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
