import { useState, useEffect } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  Switch,
  TextField,
  CssBaseline,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InputMask from "react-input-mask";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import alunoService from "../alunoService";
import { toast } from "react-toastify";

const defaultTheme = createTheme();

export default function FormPostUser() {
  const navigate = useNavigate();
  const { postUser, postAluno } = alunoService();
  const [userForm, setUserForm] = useState({});
  const [userUuid, setUserUuid] = useState("");
  const [openFormAluno, setOpenFormAluno] = useState(false);
  const [value, setValue] = useState(0);

  // Estados para monitorar os campos do formulário de usuário
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [genero, setGenero] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [telefone, setTelefone] = useState("");
  const [ativo, setAtivo] = useState(true);

  // Estado para habilitar/desabilitar o botão "Gravar" no formulário de usuário
  const [isUserFormValid, setIsUserFormValid] = useState(false);

  // Estados para monitorar os campos do formulário de aluno
  const [endereco, setEndereco] = useState("");
  const [altura, setAltura] = useState("");
  const [profissao, setProfissao] = useState("");
  const [localTrabalho, setLocalTrabalho] = useState("");
  const [planoSaude, setPlanoSaude] = useState("");
  const [contatoEmergencia, setContatoEmergencia] = useState("");

  // Estado para habilitar/desabilitar o botão "Gravar" no formulário de aluno
  const [isAlunoFormValid, setIsAlunoFormValid] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Verifica se todos os campos do formulário de usuário estão preenchidos
    setIsUserFormValid(
      nome && email && genero && cpf && dataNascimento && telefone
    );
  }, [nome, email, genero, cpf, dataNascimento, telefone]);

  useEffect(() => {
    // Verifica se todos os campos do formulário de aluno estão preenchidos
    setIsAlunoFormValid(
      endereco &&
        altura &&
        profissao &&
        localTrabalho &&
        planoSaude &&
        contatoEmergencia
    );
  }, [
    endereco,
    altura,
    profissao,
    localTrabalho,
    planoSaude,
    contatoEmergencia,
  ]);

  const handleSubmitPostUser = async (event) => {
    event.preventDefault();
    const formatData = () => {
      const partes = dataNascimento.split("/");
      const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
      return dataFormatada;
    };
    const form = {
      nome,
      email,
      sexo: genero,
      cpf: cpf.replace(/[^0-9]/g, ""),
      data_nascimento: formatData(),
      telefone: telefone.replace(/\s/g, ""),
      ativo: ativo ? 1 : 0,
    };

    setUserForm(form);
    setOpenFormAluno(true);
    setValue(1);
  };

  const handleSubmitPostAluno = async (event) => {
    event.preventDefault();
    const alunoForm = {
      endereco,
      altura,
      profissao,
      local_trabalho: localTrabalho,
      plano_saude: planoSaude,
      contato_emergencia: contatoEmergencia,
      user_uuid: userUuid,
    };

    try {
      const response = await postUser(userForm);
      setUserUuid(response.data.uuid);
      await postAluno({ ...alunoForm, user_uuid: response.data.uuid });
      navigate("/alunos");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        mt: 3,
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container maxWidth="xl">
          <CssBaseline />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Usuário" />
              <Tab label="Aluno" disabled={!openFormAluno} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmitPostUser}
              sx={{ mt: 3 }}
            >
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="nome"
                    label="Nome"
                    name="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12}>
                    <FormLabel id="genero">Gênero</FormLabel>
                  </Grid>
                  <FormControlLabel
                    value="F"
                    control={<Radio />}
                    label="Feminino"
                    name="genero"
                    onChange={(e) => setGenero(e.target.value)}
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio />}
                    label="Masculino"
                    name="genero"
                    onChange={(e) => setGenero(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="999.999.999-99"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="cpf"
                        label="CPF"
                        name="cpf"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="99/99/9999"
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="data_nascimento"
                        label="Nascimento"
                        name="data_nascimento"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputMask
                    mask="99 999999999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {() => (
                      <TextField
                        required
                        fullWidth
                        id="telefone"
                        label="Contato"
                        name="telefone"
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    id="ativo"
                    required
                    control={<Switch defaultChecked />}
                    label="Ativo"
                    name="ativo"
                    checked={ativo}
                    onChange={(e) => setAtivo(e.target.checked)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} mt={8}>
                {!value == 1 && (
                  <Button
                    sx={{ marginRight: "8px" }}
                    onClick={() => {
                      navigate("/alunos");
                    }}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  disabled={!isUserFormValid}
                >
                  Gravar
                </Button>
              </Grid>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmitPostAluno}
              sx={{ mt: 3 }}
            >
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="endereco"
                    label="Endereço"
                    name="endereco"
                    value={endereco}
                    onChange={(e) => setEndereco(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="altura"
                    label="Altura"
                    name="altura"
                    value={altura}
                    onChange={(e) => setAltura(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="profissao"
                    label="Profissão"
                    name="profissao"
                    value={profissao}
                    onChange={(e) => setProfissao(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="local_trabalho"
                    label="Local de Trabalho"
                    name="local_trabalho"
                    value={localTrabalho}
                    onChange={(e) => setLocalTrabalho(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="plano_saude"
                    label="Plano de Saúde"
                    name="plano_saude"
                    value={planoSaude}
                    onChange={(e) => setPlanoSaude(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    required
                    fullWidth
                    id="contato_emergencia"
                    label="Contato de Emergência"
                    name="contato_emergencia"
                    value={contatoEmergencia}
                    onChange={(e) => setContatoEmergencia(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={3} mt={8}>
                {!value == 1 && (
                  <Button
                    sx={{ marginRight: "8px" }}
                    onClick={() => {
                      navigate("/alunos");
                    }}
                  >
                    Cancelar
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                  disabled={!isAlunoFormValid}
                >
                  Gravar
                </Button>
              </Grid>
            </Box>
          </CustomTabPanel>
        </Container>
      </ThemeProvider>
    </Box>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
